import React, { useContext } from "react"
import { StoreContext } from '../../context/StoreContext'
import Cart from '../Cart/Cart'
import { useTransition } from 'react-spring'
import Loader from '../Loader'
import Nav from './nav/nav'
import HeaderStyles from './header.module.css'
import Logo from './headerLogo/logo'
import CartButton from '../Cart/HeaderCartButton'

const Header = () => {
  const { isCartOpen, toggleCartOpen, checkout } = useContext(StoreContext)

  //react spring animation
  const transitions = useTransition(isCartOpen, null, {
    from: { transform: 'translate3d(100%,0,0)' },
    enter: { transform: 'translate3d(0,0,0)' },
    leave: { transform: 'translate3d(100%,0,0)' }
  })
  //cart item quantity
  const qty = checkout.lineItems.reduce((total, item) => {
    return total + item.quantity
  }, 0);

  return (
    <>
      <header>

        <div className={HeaderStyles.logoandnav}>
          <Logo />
          <Nav />
        </div>
        <CartButton qty={qty} />

        {transitions.map(({ item, key, props }) => item && <Cart key={key} style={props} />)}


      </header>
      <Loader />
    </>
  )
}

export default Header
