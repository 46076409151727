import React, { useContext } from "react"
import HeaderStyles from '../header/header.module.css'
import { FaShoppingCart } from "react-icons/fa"
import { StoreContext } from '../../context/StoreContext'

const HeaderCartButton = ({ qty }) => {
    const { toggleCartOpen } = useContext(StoreContext)
    return (
        <div>
            <button className={HeaderStyles.carticon} onClick={toggleCartOpen}>
                {qty > 0 &&
                    <div className={HeaderStyles.abovecarticon}>{qty}</div>
                }
                <FaShoppingCart className={HeaderStyles.cartSize} />
            </button>
        </div>
    )
}

export default HeaderCartButton
