import React from 'react'
import { Link } from "gatsby"
import HeaderStyles from "../header.module.css"

const logo = () => {
    return (
        <Link to="/">
            <h1 className={HeaderStyles.siteLogo}>Colorful Phone Cases</h1>
        </Link>
    )
}

export default logo
