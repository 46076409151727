import React, { useContext } from "react"
import { StoreContext } from "../../context/StoreContext"
import { animated } from 'react-spring'
import CartCoupon from './CartCoupon'
import CartItems from './CartItems'
import Styles from './css/Cart.module.css'
import './css/Cart.css'


const Cart = ({ style }) => {
  const { checkout, toggleCartOpen, RemoveProductfromCart, addProductToCart, RemoveOneProductfromCart } = useContext(StoreContext)

  return (

    <animated.div className={Styles.cart}
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 1000,
        height: "100vh",
        background: "white",
        padding: 60,
        overflowY: "scroll",
        ...style,
      }}
    >

      <button onClick={toggleCartOpen} id="closecart" >Close</button>

      <h3 id="cartTitle">Cart</h3>


      <CartItems checkout={checkout} RemoveProductfromCart={RemoveProductfromCart} addProductToCart={addProductToCart} RemoveOneProductfromCart={RemoveOneProductfromCart} />
      <CartCoupon />
      <div>
        <p id="notice"><span style={{ color: "green" }}><strong>NOTICE:</strong></span> All cases are individualy crafted on demand and could take up to a few days to create before being shipped.</p>
        <p id="notice"><span style={{ color: "green" }}><strong>NOTICE:</strong></span> We currently do not accept returns, However, if your case is damaged on arrival or lost in shipping, please contact us as soon as possible.</p>
      </div>
      <div id="proceedtoCheckoutButton" >
        <a href={checkout.webUrl}>Proceed to Checkout</a>
      </div>

    </animated.div >

  )
}

export default Cart
