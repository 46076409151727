import React, { useState } from "react"
import NavStyles from './nav.module.css'
import { Link } from "gatsby"

const Navcontents = () => {
    const [isThemeNavVisible, setIsThemeNavVisible] = useState(false);



    const toggleThemeNav = () => {
      setIsThemeNavVisible(!isThemeNavVisible);
      console.log(isThemeNavVisible)
    };
  
    return (
      
            <>
             <div className={NavStyles.nonMobile}>
                <ul className={NavStyles.navlist}>
                    <li><Link to="/" className={NavStyles.navitem}>Home</Link></li>
                    <li><Link to="/iphonecases" className={NavStyles.navitem}>iPhone Cases</Link></li>
                    <li><Link to="/samsungcases" className={NavStyles.navitem}>Samsung Cases</Link></li>
                    <li className={NavStyles.navThemeWrapper }><div className={NavStyles.navThemeItem}>Themes</div>
                        <div className={NavStyles.navSubContainer} >
                            <Link to='/abstract-phone-cases' className={NavStyles.navSubItem}>Abstract Phone Cases</Link>
                            <Link to='/trippy-phone-cases' className={NavStyles.navSubItem}>Trippy Phone Cases</Link>
                            <Link to='/mandala-phone-cases' className={NavStyles.navSubItem}>Mandala Phone Cases</Link>
                        </div>
                   
                    </li>
                </ul>
                </div>

            <div className={NavStyles.mobile}>
                <ul className={NavStyles.navlist}>
                    <li><Link to="/" className={NavStyles.navitem}>Home</Link></li>
                    <li><Link to="/iphonecases" className={NavStyles.navitem}>iPhone Cases</Link></li>
                    <li><Link to="/samsungcases" className={NavStyles.navitem}>Samsung Cases</Link></li>
                    <li className={NavStyles.navThemeWrapper }><button className={NavStyles.navThemeItem} onClick={toggleThemeNav}>Themes <p className={NavStyles.mobilePlus}>+</p></button>
                    { (isThemeNavVisible) && (
                        <div className={NavStyles.navSubContainer} >
                            <Link to='/abstract-phone-cases' className={NavStyles.navSubItem}>Abstract Phone Cases</Link>
                            <Link to='/trippy-phone-cases' className={NavStyles.navSubItem}>Trippy Phone Cases</Link>
                            <Link to='/mandala-phone-cases' className={NavStyles.navSubItem}>Mandala Phone Cases</Link>
                        </div>
                    )
                    }
                    </li>
                </ul>
                </div>
            </>
       
    )
}

export default Navcontents
