import React from 'react';
import Styles from './css/Cart.module.css';
import './css/CartItems.css';


const CartItems = ({ checkout, RemoveProductfromCart, addProductToCart, RemoveOneProductfromCart }) => {

    function SubtractfromCart(item) {

        RemoveOneProductfromCart(item);

    }

    return (
        <>

            {checkout.lineItems.map(item => (

                <div className={Styles.cartitemcontainer} key={item.id}>

                    <div className={Styles.productRowWrapper}>
                        <span className={Styles.title}>Product: </span>
                        <h4 className={Styles.item}>{item.title}</h4>
                    </div>
                    <div className={Styles.productRowWrapper}>
                        <span className={Styles.title}>Variant: </span>
                        <h4 className={Styles.item}>{item.variant.title}</h4>
                    </div>
                    <div className={Styles.productRowWrapper}>
                        <span className={Styles.title}>Quantity: </span>
                        <span><button className={Styles.QuantityButton} onClick={() => SubtractfromCart(item)}>-</button></span><p className={Styles.item}>{item.quantity}</p><span><button className={Styles.QuantityButton} onClick={() => addProductToCart(item.variant.id)}>+</button></span>
                    </div>

                    <div id="priceWrapper">
                        <span id="priceTitle">Price:    </span>
                        <p className={Styles.item}>${item.variant.price}</p>
                    </div>

                    <button onClick={() => RemoveProductfromCart(item.id)} className={Styles.removebutton}>Remove</button>

                    <span className={Styles.borderbottom}></span>

                </div>

            ))}
        </>
    )
}

export default CartItems
