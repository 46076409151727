import React, { useContext, useState } from "react"
import Styles from './css/Cart.module.css';
import { StoreContext } from "../../context/StoreContext"
import "./css/coupon.css"

const CartCoupon = () => {

    const { checkout, checkCoupon, removeCoupon } = useContext(StoreContext)
    const [coupon, setCoupon] = useState('')

    return (
        <div>

            {checkout.discountApplications.length > 0 ? (
                <div>
                    <p> Applied Coupon: </p> {checkout.discountApplications[0].code}:
                    {checkout.discountApplications[0].value.percentage}% Off

                    <button className={Styles.button} id="removeCoupon" onClick={() => {
                        removeCoupon(checkout.discountApplications[0].code)
                    }}>
                        Remove
                    </button>

                </div>
            ) : (
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        checkCoupon(coupon)
                    }}>

                        <div >
                            <label htmlFor="coupon" className={Styles.labeltext}>Enter Coupon</label>
                            <input id="coupon" value={coupon} onChange={e => setCoupon(e.target.value)} type="text" />
                            <button className={Styles.button} id="checkcouponbutton">Check Coupon</button>
                        </div>
                    </form>
                )}
        </div >
    )
}

export default CartCoupon
