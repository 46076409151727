import React, { useState } from "react"
import NavStyles from './nav.module.css';
import NavContents from './navcontents'
import "./nav.css"

const Nav = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);



  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  
 

  return (
    <>
      <nav className={NavStyles.nonMobile}>
        <NavContents />
      </nav>

      {(isNavVisible) && (
        <nav className={NavStyles.mobile}>
          <NavContents />
        </nav>
      )}


      <button onClick={toggleNav} id="burger">
        <div></div>
        <div></div>
        <div></div>
      </button>

    </>
  )

}

export default Nav
