import React from 'react'
import { Link } from "gatsby"
import Styles from '../layout.module.css'
const Footer = () => {
    return (
        <footer className={Styles.footer}>
            <div className={Styles.list}>
                <Link to="/contactus" className={Styles.privacypolicy} style={{ marginBottom: '15px', marginTop: '5px', fontSize: 'larger' }}>Contact Us</Link>
                <Link to="/privacypolicy" className={Styles.privacypolicy}>Privacy Policy</Link>
                <Link to="/returnpolicy" className={Styles.privacypolicy}>Return Policy</Link>
                <Link to="/termsofservice" className={Styles.privacypolicy}>Terms of Service</Link>
            </div>
        </footer>
    )
}

export default Footer
