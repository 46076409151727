import React, { useContext } from "react"
import { StoreContext } from '../context/StoreContext'
import { animated, useTransition } from 'react-spring'

const Loader = () => {
    const { isLoading } = useContext(StoreContext)


    const transitions = useTransition(isLoading, null, {
        from: { transform: 'translate3d(0%,0,0)', opacity: 0 },
        enter: { transform: 'translate3d(0%,0,0)', opacity: .8 },
        leave: { transform: 'translate3d(0%,0,0)', opacity: 0 }
    })

    return transitions.map(({ item, key, props }) => item && (
        <animated.div key={key} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'black', ...props }}>
            <h6 style={{ position: 'fixed', top: '46%', left: '46%', color: 'white', fontSize: '30px' }}>
                Loading...
            </h6>
        </animated.div >

    ))

}

export default Loader
